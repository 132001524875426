import React from "react";
import { Container } from "react-bootstrap";

import NavBar from "../components/navbar";
import CardComponent from "../components/card/card";
import AutoForm from "../components/forms/autoform";

// markup
const AutosPage = () => {
  return (
    <>
      <NavBar />

      <Container>
        <h1 className="text-center" style={{ margin: "30px 0" }}>
          Autos & Voitures usagées et d’occasion à vendre
        </h1>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
            alignItems: "stretch",
            gap: "2em 0",
          }}
        >
          <CardComponent />
          <CardComponent />
          <CardComponent />
          <CardComponent />
        </div>
        <AutoForm />
      </Container>
    </>
  );
};

export default AutosPage;
